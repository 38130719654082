import React from "react"
import styled from "styled-components"

import SEO from "../components/seo"
import "../styles/global.css"
import "../styles/normalize.css"

const Main = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
`

const NotFoundPage = () => (
  <>
    <SEO />
    <Main>
      <h1>404 NOT FOUND</h1>
    </Main>
  </>
)

export default NotFoundPage
